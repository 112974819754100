import styled from 'styled-components';

const TdLeft = styled.td`
  font-weight: 600;
  color: #0e7ee4;
  text-align: left;
  height: 40px;
  width: 30%;
  overflow: hidden;
`;

const Tr = styled.tr`
  border-bottom: 1px solid #dbe4ff;
  padding: 0px 16px;
`;

export const InformationSection = ({ deviceInfo }: any) => {
  return (
    <table
      style={{
        width: '100%',
        height: 'fit-content',
        tableLayout: 'fixed',
        overflow: 'hidden'
      }}
    >
      <tbody
        style={{
          width: '100%',
          height: 'fit-content',
          padding: '0px 16px',
          display: 'block',
        }}
      >
        {deviceInfo &&
          Object.keys(deviceInfo).map((key: string) => (
            <Tr key={key}>
              <TdLeft>{key}</TdLeft>
              <td
                style={{
                  overflow: 'hidden',
                  width: '70%',
                  whiteSpace: 'nowrap',
                }}
              >
                {deviceInfo[key] && deviceInfo[key].toString()}
              </td>
            </Tr>
          ))}
      </tbody>
    </table>
  );
};
