import styled from 'styled-components';
import SolarPath from 'assets/pages/Dashboard/SolarPathSvg.svg';
import Sun from 'assets/pages/Dashboard/DeviceControl/Sun.svg';
import SiteImageSvg from 'assets/pages/Dashboard/SiteImageSvg.svg';

const SunContainer = styled.div`
  position: relative;
  margin-top: 16px;
  width: fit-content;
  height: fit-content;
  display: flex;
`;

const SunPathImage = styled.img<any>`
  width: 459px;
  height: 75px;
`;

const SunImage = styled.img<any>`
  position: absolute;
  width: 34px;
  height: 34px;
  left: ${(props) => `${props.left}%`};
  top: ${(props) => `${props.top}%`};
`;

export const OverviewSection = () => {
  const getPosition = (start: any, stop: any) => {
    const now = new Date().getHours() * 60 + new Date().getMinutes();
    const startTime = new Date(start).getHours() * 60 + new Date().getMinutes();
    const stopTime = new Date(stop).getHours() * 60 + new Date().getMinutes();
    const percent = Math.round(((now - startTime) / (stopTime - startTime)) * 12);
    const leftList: number[] = [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96];
    const topList: number[] = [67, 33, 12, 8, -2, -18, -19, -17, -8, 1, 17, 67];
    if (now < startTime) {
      return null;
    } else if (now > stopTime) {
      return null;
    } else {
      return {
        left: leftList[percent - 1],
        top: topList[percent - 1],
      };
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SunContainer>
        <SunPathImage src={SolarPath} />
        <SunImage
          src={Sun}
          left={
            getPosition('Thursday, October 28, 2021 7:18:56', 'Thursday, October 28, 2021 19:18:56')
              ?.left
          }
          top={
            getPosition('Thursday, October 28, 2021 7:18:56', 'Thursday, October 28, 2021 19:18:56')
              ?.top
          }
        />
      </SunContainer>
      <img src={SiteImageSvg} />
    </div>
  );
};
