import styled from 'styled-components';
import { BlockContainer, BlockHeader, GraphContainer } from 'components/Common/components';
import { Row, Col } from 'antd';
import { LoadingPage } from 'components/Common/LoadingPage/LoadingPage';
import { ResponsivePie } from '@nivo/pie';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 18px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 200px;
`;

export const PieChartComponent = (prop: any) => (
  <ResponsivePie
    data={prop.data}
    margin={{ top: 20, right: 120, bottom: 30, left: 0 }}
    innerRadius={0.6}
    colors={{ scheme: 'blues' }}
    padAngle={1}
    cornerRadius={0}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    enableArcLinkLabels={false}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
    arcLabelsSkipAngle={10}
    // arcLabelsTextColor={{ from: 'color', modifiers: [[prop.color ? prop.color : 'darker', 3]] }}
    arcLabelsRadiusOffset={1.85}
    arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 0]] }}
    // arcLabelsTextColor={'#ffcfde'}
    defs={[
      {
        id: 'iot_disconnected',
        type: 'patternDots',
        background: '#EF4337',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 0,
        padding: 1,
        stagger: true,
      },
      {
        id: 'request_service',
        type: 'patternLines',
        background: '#F5A623',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 0,
        spacing: 10,
      },
      {
        id: 'under_repaired',
        type: 'patternLines',
        background: '#065BA9',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 0,
        spacing: 10,
      },
      {
        id: 'repaired',
        type: 'patternLines',
        background: '#14B8B4',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 0,
        spacing: 10,
      },
      {
        id: 'normal',
        type: 'patternLines',
        background: '#D8E5FE',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 0,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'iot_disconnected',
        },
        id: 'iot_disconnected',
      },
      {
        match: {
          id: 'request_service',
        },
        id: 'request_service',
      },
      {
        match: {
          id: 'under_repaired',
        },
        id: 'under_repaired',
      },
      {
        match: {
          id: 'repaired',
        },
        id: 'repaired',
      },
      {
        match: {
          id: 'normal',
        },
        id: 'normal',
      },
    ]}
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 90,
        translateY: 0,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 10,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

export const AlertSummary = ({ data }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BlockHeader sm>Alert Summary</BlockHeader>
          </Col>
        </Row>
        <Row>
          <GraphContainer>
            {data && <PieChartComponent data={data !== undefined ? data : []} />}
            {data === undefined && <LoadingPage height={'100%'} />}
          </GraphContainer>
        </Row>
      </Container>
    </>
  );
};
