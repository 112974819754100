import { useState } from 'react';
import { LoginWrapper, Logo, FormContainer } from './LoginElements';
import authService from 'services/auth.service';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import LogoImage from 'assets/common/logo/AltoLogo_256x256.jpg';
import { useHistory } from 'react-router-dom';

const LoginPage = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    setLoading(true);
    authService
      .login(e.username, e.password)
      .then((res: any) => {
        localStorage.setItem('accessToken', res?.data?.token);
        localStorage.setItem('role', res?.data?.role);
        localStorage.setItem('location', JSON.stringify(res?.data?.location));
        history.push('/admin/dashboard');
      })
      .catch((err:any) => {
        toast.error('Bad credetials');
        setLoading(false);
      });
  };

  return (
    <LoginWrapper>
      <Logo src={LogoImage} alt="logo" />

      <FormContainer>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
        >
          <Typography.Title level={5}>Username</Typography.Title>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Typography.Title level={5}>Password</Typography.Title>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
      {loading && <Spin />}
      <ToastContainer />
    </LoginWrapper>
  );
};

export default LoginPage;
