import { SiteDescription } from 'pages/DashboardPage/AllStations';
import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export const Map = ({ siteData, selectedStation }: any) => {
  const [map, setMap] = useState<any>(null);
  const [center, setCenter] = useState(
    siteData && siteData[0]?.location?.split(',').map((string: string) => Number(string))
  );

  useEffect(() => {
    if (siteData && map !== null && map) {
      const tempCenter = siteData[0]?.location?.split(',').map((string: string) => Number(string));
      setCenter(tempCenter);
      map.setView(tempCenter, map.getZoom(), {
        animate: true,
      });
    }
  }, [siteData, map, selectedStation]);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <MapContainer
          center={center ? center : [16.712384, 102.574865]}
          zoom={9}
          scrollWheelZoom={true}
          style={{ height: 'calc(100vh - 67px)' }}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          {siteData &&
            siteData.map((item: any) => (
              <Marker position={item.location.split(',').map((string: string) => Number(string))}>
                <Popup>
                  <SiteDescription siteItem={item} />
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </>
  );
};
