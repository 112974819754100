import { useEffect, useState } from "react"
import styled from "styled-components";
import { BlockContainer, CalendarHeader } from "components/Common/components";
import { Row, Col } from "antd"
import moment from "moment"

const Container = styled(BlockContainer)`
  overflow: scroll;
  height: fit-content;
  flex-direction: column;
  padding: 10px 18px;
  margin-bottom: 0px;
`

const CalendarContainer = styled.div<any>`
  width: 12.5%;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  background: ${props =>
    props.today ?
      'linear-gradient(180deg, #0E7EE4 0%, #55A6F2 100%)' :
      props.selected ?
        'linear-gradient(180deg, #73bcff 0%, #b9daf8 100%)' :
        'transparent'
  };
  border-radius: 30px;
  border: 0px;
  margin: 5px 5px 0px 0px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  :last-child{
    margin-right: 0px;
  }
`

const Day = styled.div<any>`
  width: 100%;
  display:flex;
  justify-content:center;
  height: 20px;
  font-size: 12px;
  color: ${props =>
    props.today ?
      "white" :
      props.selected ? "white" :
        props.selectedMonth ?
          "#788796" :
          "#A1B7CD"};
  text-align: center;
`

const Date = styled.p<any>`
  color: ${props =>
    props.today ?
      "white" :
      props.selected ? "white" :
        props.selectedMonth ?
          "#000000" :
          "#A1B7CD"};
  font-size: 16px;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #A1B7CD;
  color: #A1B7CD;
  border-radius: 12px;
  position: absolute;
  top: 3px;
  cursor: pointer;
`

const CalendarComponent = ({ value, today, handleChangeDate, selected, selectedMonth }: any) => {
  return (
    <CalendarContainer today={today} onClick={() => handleChangeDate(value)} selected={selected}>
      <Day today={today} selectedMonth={selectedMonth} selected={selected}>
        {value.format('ddd').substring(0, 2)}
      </Day>
      <Date today={today} selectedMonth={selectedMonth} selected={selected}>
        {value.format('D')}
      </Date>
    </CalendarContainer>
  )
}


export const Calendar = ({ value }: any) => {
  const today = moment()
  const [datetimeList, setDatetimeList] = useState<any>()
  const [monthYear, setMonthYear] = useState<string>(today.format('MMMM') + " " + today.format("YYYY"))
  const [selectedDate, setSelectedDate] = useState<any>(moment())
  const [selectedMonth, setSelectedMonth] = useState<string>(today.format('MMMM'))

  const subDate = () => {
    let newDatetimeList: any[] = []
    datetimeList.forEach((date: any, index: number) => {
      newDatetimeList[index] = date.subtract(7, "days")
    })
    setDatetimeList(newDatetimeList)
    setMonthYear(newDatetimeList[3].format('MMMM') + " " + newDatetimeList[3].format("YYYY"))
  }

  const addDate = () => {
    let newDatetimeList: any[] = []
    datetimeList.forEach((date: any, index: number) => {
      newDatetimeList[index] = date.add(7, "days")
    })
    setDatetimeList(newDatetimeList)
    setMonthYear(newDatetimeList[3].format('MMMM') + " " + newDatetimeList[3].format("YYYY"))
  }

  const generateDateList = () => {
    let newDatetimeList = []
    for (let i = 3; i > 0; i--) {
      newDatetimeList.push(moment().subtract(i, "days"))
    }
    newDatetimeList.push(moment())
    setMonthYear(moment().format('MMMM') + " " + moment().format("YYYY"))
    for (let i = 1; i < 4; i++) {
      newDatetimeList.push(moment().add(i, "days"))
    }
    setDatetimeList(newDatetimeList)
  }

  const handleChangeDate = (date: any) => {
    setSelectedDate(date)
    setMonthYear(date.format('MMMM') + " " + date.format("YYYY"))
  }

  useEffect(() => {
    generateDateList()
  }, [])

  return (
    <>
      <Container>
        <Row>
          <Col style={{ position: 'relative' }} sm={24}>
            <CalendarHeader>
              {monthYear}
            </CalendarHeader>
            <ArrowIcon
              style={{ right: '40px' }}
              onClick={() => subDate()} >
              {"<"}
            </ArrowIcon>
            <ArrowIcon
              style={{ right: '10px' }}
              onClick={() => addDate()} >
              {">"}
            </ArrowIcon>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          {datetimeList && datetimeList.map((datetime: any) =>
            <>
              <CalendarComponent
                key={datetime.format('D') + datetime.format('M')}
                value={datetime}
                today={datetime.format('D') + datetime.format('M') === today.format('D') + today.format('M')}
                handleChangeDate={handleChangeDate}
                selected={datetime.format('D') + datetime.format('M') === selectedDate.format('D') + selectedDate.format('M')}
                selectedMonth={selectedMonth === datetime.format('MMMM')}
              />
            </>
          )}
        </Row>
      </Container>
    </>
  )
}