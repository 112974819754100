export const Box2 = ({ active }: any) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H7C7.55228 0 8 0.447715 8 1V15C8 15.5523 7.55228 16 7 16H1C0.447715 16 0 15.5523 0 15V1Z"
        fill={active ? 'white' : '#0E7EE4'}
      />
      <path
        d="M10 1C10 0.447715 10.4477 0 11 0H17C17.5523 0 18 0.447715 18 1V15C18 15.5523 17.5523 16 17 16H11C10.4477 16 10 15.5523 10 15V1Z"
        fill={active ? 'white' : '#0E7EE4'}
      />
    </svg>
  );
};
