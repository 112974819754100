import styled from 'styled-components';
import { BlockContainer, BlockHeader, GraphContainer } from 'components/Common/components';
import { Row, Col } from 'antd';
import SiteImageSvg from 'assets/pages/Dashboard/SiteImageSvg.svg';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 18px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: fit-content;
  
`;

export const PumpCapacity = () => {
  return (
    <Container>
      <Row>
        <Col sm={24} lg={24}>
          <BlockHeader sm>Khon Kaen Installed Capacity</BlockHeader>
        </Col>
      </Row>
      <Row>
        <img style={{width:'100%', height: '100%', padding:'12px'}} src={SiteImageSvg} />
      </Row>
    </Container>
  );
};
