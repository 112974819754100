import styled from 'styled-components';
import Modal from 'react-modal';
import { Col, Row } from 'antd';
import { CommonButton } from 'components/Common/CommonButton/CommonButton';
import SubTab from 'components/SubTab';
import { OverviewSection } from './OverviewSection';
import { ServiceRequestSection } from './ServiceRequestSection';
import CO2 from 'assets/pages/Dashboard/CO2.svg';
import { InformationSection } from './InformationSection';
import { AnalyticSection } from './AnalyticSection';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: '0.5px solid #f3f3f3',
    background: 'white',
    padding: '0px',
  },
  overlay: {
    backgroundColor: '#00000066',
    zIndex: 99999,
  },
};

const Container = styled.div`
  width: 1023px;
  max-height: 85vh;
  position: relative;
  display: flex;
`;

const Location = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #788796;
  margin-bottom: 6px;
`;

const StationName = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: #0e7ee4;
`;

const Co2Box = styled.div`
  position: absolute;
  display: flex;
  width: 80%;
  left: 10%;
  bottom: 20px;
  height: 96px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 8px rgba(145, 158, 171, 0.16), 0px 16px 24px -8px rgba(14, 126, 228, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 16px;
`;

const tabNameList = [
  { title: 'Overview', value: 'overview' },
  { title: 'Information', value: 'information' },
  { title: 'Service Request', value: 'servicerequest' },
  { title: 'Analytic', value: 'analytic' },
];

export const StationInfoModal = ({
  isOpen,
  handleCloseModal,
  locationData,
  deviceData,
  selectedTabName,
  handleSelectTabName,
  serviceRequestData,
  selectedStation,
  deviceInfo,
}: any) => {
  return (
    <>
      <Modal style={customStyles} isOpen={isOpen} onRequestClose={handleCloseModal}>
        <Container>
          <Row style={{ width: '100%' }}>
            <Col xs={16} style={{ padding: '42px 42px 21px 42px' }}>
              <Row>
                <Col xs={18}>
                  <Location>
                    <span>{locationData?.province} / </span>
                    <span>{locationData?.district} / </span>
                    <span>{locationData?.zone}</span>
                  </Location>
                  <StationName>{deviceData.device_name}</StationName>
                </Col>
                <Col
                  xs={6}
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                >
                  <CommonButton text={'Request Service'} style={{ marginBottom: '12px' }} />
                  <CommonButton text={'Edit'} bgColor={'white'} color={'#0e7ee4'} />
                </Col>
                <Col xs={24}>
                  <SubTab
                    dataList={tabNameList}
                    selectedValue={selectedTabName}
                    handleSelect={handleSelectTabName}
                    size={'small'}
                    selectedTextColor={'white'}
                    agentId={deviceData && deviceData.agent_id}
                  />
                </Col>
                <Col
                  xs={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '400px',
                    overflow: 'scroll',
                  }}
                  id="display-scroll"
                >
                  {selectedTabName === 'overview' && <OverviewSection />}
                  {selectedTabName === 'servicerequest' && (
                    <ServiceRequestSection data={serviceRequestData} />
                  )}
                  {selectedTabName === 'information' && (
                    <InformationSection deviceInfo={deviceInfo} />
                  )}
                  {selectedTabName === 'analytic' && <AnalyticSection deviceInfo={deviceInfo} />}
                </Col>
              </Row>
            </Col>
            <Col xs={8}>
              <Col
                xs={24}
                style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}
              >
                <img style={{ height: '620px' }} src={selectedStation?.image} />
                <Co2Box>
                  <img src={CO2} style={{ marginRight: '12px' }} />
                  <Row>
                    <Col xs={24} style={{ height: '22px' }}>
                      <p style={{ margin: '0px' }}>CO2 Emissions (kg)</p>
                    </Col>
                    <Col xs={12}>
                      <small>Daily</small>
                      <p>1,260</p>
                    </Col>
                    <Col xs={12}>
                      <small>Accomulate</small>
                      <p>3,260</p>
                    </Col>
                  </Row>
                </Co2Box>
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
};
