import { Col, Row, Progress, Rate } from 'antd';
import styled from 'styled-components';
import Line from 'assets/pages/Dashboard/Line.svg';
import { convertDatetimeToStr } from 'utils/convertDatetime';

const Card = styled.div`
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24);
  border-radius: 16px;
  padding: 14px 20px;
  margin-bottom: 16px;
`;

const LineButton = styled.button`
  width: 64px;
  height: 24px;
  background: rgba(20, 184, 180, 0.1);
  border: 1px solid #14b8b4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  color: #14b8b4;
`;

const Role = styled.p`
  font-size: 10px;
  color: #212529;
  margin-bottom: 0px;
`;

const Detail = styled.p`
  font-size: 14px;
  color: #788796;
  margin-bottom: 0px;
`;

const UpdatedAt = styled.span`
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #065ba9;
`;

export const ServiceRequestSection = ({ data }: any) => {
  return (
    <>
      <Row>
        <Col xs={24} >
          {data &&
            data.map((item: any) => (
              <Card>
                <Row style={{ width: '100%' }}>
                  <Col xs={10}>
                    <Row>
                      <Col xs={24}>
                        <p style={{ margin: 0 }}>
                          Request No :{' '}
                          <span style={{ fontSize: '20px', color: '#0e7ee4' }}>
                            {item?.request_no}
                          </span>
                        </p>
                      </Col>
                      <Col xs={24}>
                        <Detail>{item?.detail}</Detail>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={14}>
                    <Row>
                      <Col
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-end',
                        }}
                      >
                        <p style={{ margin: '0px', height: 'fit-content' }}>
                          <UpdatedAt>{convertDatetimeToStr(item?.updated_at)}</UpdatedAt>
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        <p style={{ margin: '0px', height: 'fit-content', marginBottom: '-3px' }}>
                          Progress :{' '}
                          <span style={{ fontSize: '20px', fontWeight: 700, color: '#14b8b4' }}>
                            {item?.progress}%
                          </span>
                        </p>
                      </Col>
                      <Col xs={24} style={{ marginBottom: '6px' }}>
                        <Progress percent={item?.progress} status="active" showInfo={false} />
                      </Col>
                      <Col xs={24}>
                        <Row>
                          <Col xs={5}>
                            <img style={{ width: '60px' }} src={item?.profile} />
                          </Col>
                          <Col xs={13}>
                            <Row>
                              <Col xs={24}>
                                <Role>{item?.requestor_role}</Role>
                              </Col>
                              <Col xs={24}>{item?.requestor_name}</Col>
                              <Col xs={24}>
                                <Rate
                                  disabled
                                  defaultValue={item?.requestor_score}
                                  style={{ fontSize: '14px' }}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                            }}
                          >
                            <LineButton onClick={() => console.log(item?.requestor_lineid)}>
                              <img src={Line} style={{ marginRight: '4px' }} /> LINE
                            </LineButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ))}
        </Col>
      </Row>
    </>
  );
};
