import { useContext, useEffect, useState, useRef } from 'react';
import { InnerContainer, PageContainer } from 'components/Common/components';
import { FirebaseContext } from '../../firebase';
import { Col, Row } from 'antd';
import { FilterBox } from './FilterBox';
import { AllStations } from './AllStations';
import { Map } from 'components/Map/Map';
import { Summary } from './Summary';
import { StationInfoModal } from './StationInfoModal';
import Technician from 'assets/pages/Dashboard/Technician.png';
import { getLocations } from 'services/api.service';
import { Serie } from '@nivo/line';

// const data: any = [
//   {
//     image: MockSolarSite,
//     stationName: 'Solar Station',
//     location: 'Location of solar station',
//     value: 30,
//     unit: 'kWh',
//     updatedAt: new Date(),
//     problem_type: 'IoT Disconnected',
//   },
//   {
//     image: Site,
//     stationName: 'Solar Station',
//     location: 'Location of solar station',
//     value: 30,
//     unit: 'kWh',
//     updatedAt: new Date(),
//     problem_type: 'Under Repaired',
//   },
//   {
//     image: Site,
//     stationName: 'Solar Station',
//     location: 'Location of solar station',
//     value: 30,
//     unit: 'kWh',
//     updatedAt: new Date(),
//     problem_type: '',
//   },
//   {
//     image: MockSolarSite,
//     stationName: 'Solar Station',
//     location: 'Location of solar station',
//     value: 30,
//     unit: 'kWh',
//     updatedAt: new Date(),
//     problem_type: 'Repaired',
//   },
// ];

export const EmptyDeviceData = {
  solar: {
    title: 'Solar',
    online: '120',
    total: '125',
  },
  water_pump: {
    title: 'Water Pump',
    online: '30',
    total: '31',
  },
  environment_sensor: {
    title: 'Environment Sensor',
    online: '24',
    total: '24',
  },
};

const serviceRequestData: any[] = [
  {
    request_no: 'AQ009731',
    detail: 'Detail of service request will be described here.',
    progress: 80,
    updated_at: new Date(),
    requestor_role: 'Technician',
    requestor_name: 'Firstname Lastname',
    requestor_score: 5,
    requestor_lineid: 'aaaaa',
    problem_type: 'IoT Disconnected',
    profile: Technician,
  },
  {
    request_no: 'AQ009731',
    detail: 'Detail of service request will be described here.',
    progress: 80,
    updated_at: new Date(),
    requestor_role: 'Technician',
    requestor_name: 'Firstname Lastname',
    requestor_score: 5,
    requestor_lineid: 'aaaaa',
    problem_type: 'IoT Disconnected',
    profile: Technician,
  },
];

const MockEnergyData:any = [
  { label: '12-11', 'Energy Generation': 12, 'Water Pumped': 84 },
  { label: '12-12', 'Energy Generation': 12, 'Water Pumped': 84 },
  { label: '12-13', 'Energy Generation': 12, 'Water Pumped': 84 },
];

const DashboardPage = () => {
  const [isToggle, setIsToggle] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [iotDevices, setIotDevices] = useState(EmptyDeviceData);
  const [locationData, setLocationData] = useState({
    province: 'KHONKAEN',
    district: 'District1',
    zone: 'Zone 1',
  });
  const [deviceData, setDeviceData] = useState({ device_name: 'Solar Station 1' });
  const [selectedTabName, setSelectedTabName] = useState('overview');
  const [selectedStation, setSelectedStation] = useState();
  const [activeButton, setActiveButton] = useState('thumbnailView');
  const [siteData, setSiteData] = useState();
  const [energyData, setEnergyData] = useState<any[]>();
  const [powerData, setPowerData] = useState<Serie[]>();
  const [alertSummaryData, setAlertSummaryData] = useState<any>([
    {
      id: 'iot_disconnected',
      label: 'IoT Disconnected',
      value: 1,
      color: 'hsl(124, 70%, 50%)',
    },
    {
      id: 'request_service',
      label: 'Request Service',
      value: 3,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'under_repaired',
      label: 'Under Repaired',
      value: 1,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'repaired',
      label: 'Repaired',
      value: 4,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'normal',
      label: 'Normal',
      value: 3,
      color: 'hsl(311, 70%, 50%)',
    },
  ]);
  const firebase = useContext<any>(FirebaseContext);

  const onChange = () => {
    setIsToggle(!isToggle);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const onSelectStation = (stationData: any) => {
    setSelectedStation(stationData);
    setIsOpen(true);
  };

  const handleSelectDevice = (stationData: any) => {};

  const handleSelectTabName = (tabName: string) => {
    setSelectedTabName(tabName);
  };

  const toggleViewButton = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const fetchLocations = async () => {
    try {
      const result = await getLocations();
      setSiteData(result.data.locations);
      const energy_mater: any[] = [
        {
          id: 'Power Generation',
          data: result?.data?.energy_meter?.total_power.map((item: any) => ({
            y: item.power,
            x: item.timestamp,
          })),
        },
      ];
      setPowerData(energy_mater);
      setEnergyData(MockEnergyData);
    } catch (err) {
      console.log(err);
      window.alert('Cannot fetch data');
    }
  };

  useEffect(() => {
    const isLogin = localStorage.getItem('accessToken');
    if (!isLogin) {
      localStorage.clear();
      window.location.href = '/login';
    }
    fetchLocations();
  }, []);

  return (
    <>
      <PageContainer>
        <InnerContainer style={{ paddingRight: '0px', overflowX: 'hidden' }}>
          <Row gutter={20}>
            <Col xs={12} lg={16}>
              <Row>
                <Col xs={24}>
                  <FilterBox
                    isToggle={isToggle}
                    onChange={onChange}
                    activeButton={activeButton}
                    toggleViewButton={toggleViewButton}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <AllStations
                    data={siteData}
                    onSelect={onSelectStation}
                    handleSelectDevice={handleSelectDevice}
                    activeButton={activeButton}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={8} style={{ maxHeight: 'calc(100vh - 67px)', overflowY: 'scroll' }}>
              {isToggle ? (
                <Map siteData={siteData} selectedStation={selectedStation} />
              ) : (
                <Summary
                  iotDevices={iotDevices}
                  energyData={energyData}
                  powerData={powerData}
                  alertSummaryData={alertSummaryData}
                />
              )}
            </Col>
          </Row>
        </InnerContainer>
      </PageContainer>
      <StationInfoModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        locationData={locationData}
        deviceData={deviceData}
        selectedTabName={selectedTabName}
        handleSelectTabName={handleSelectTabName}
        serviceRequestData={serviceRequestData}
        selectedStation={selectedStation}
        deviceInfo={selectedStation}
      />
    </>
  );
};

export default DashboardPage;
