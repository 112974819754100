import { useState } from 'react';
import styled from 'styled-components';
import UserIcon from 'assets/common/icon/user.svg';
import DropdownIcon from 'assets/common/icon/dropdown.svg';
import { useSpring, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
`;

const Block = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 15px;
  cursor: pointer;
`;

const ProfileSym = styled.div`
  width: 35px;
  height: 35px;
  background: url(${UserIcon}) center no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileName = styled.p`
  font-size: 14px;
  margin: 0px;
  line-height: 16px;
  margin-right: 20px;
  :hover {
    opacity: 0.8;
  }
`;

const DropdownImage = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 7px;
  margin-top: 2px;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 50px;
  right: 0px;
  width: 150px;
  height: fit-content;
  padding: 5px 15px 10px 15px;
  background-color: white;
  border-radius: 5px;
`;

const DropdownRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 10px 0px;
  :hover {
    opacity: 0.8;
  }
`;

const TransparentBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const AnimatedDropdownContainer = animated(DropdownContainer);

type ProfleDropdownProps = {
  open: boolean;
};

const ProfileDropdown = ({ open }: ProfleDropdownProps) => {
  let history = useHistory();
  const dropdownProps = useSpring({
    opacity: open ? '1' : '0',
    display: open ? 'block' : 'none',
  });

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <>
      <AnimatedDropdownContainer style={dropdownProps}>
        {/* <DropdownRow onClick={() => window.location.href = "/admin/myprofile"}>
          My profile
        </DropdownRow> */}
        <DropdownRow onClick={() => handleLogout()}>Logout</DropdownRow>
      </AnimatedDropdownContainer>
    </>
  );
};

const Profile = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <TransparentBg onClick={() => setOpen(!open)} />}
      <Container>
        <Block>
          <ProfileSym onClick={() => setOpen(!open)} />
        </Block>
        <Block>
          <ProfileName onClick={() => setOpen(!open)}>
            AltoTech
            <br />
            <small>Admin</small>
          </ProfileName>
          <DropdownImage src={DropdownIcon} onClick={() => setOpen(!open)} />
          <ProfileDropdown open={open} />
        </Block>
      </Container>
    </>
  );
};

export default Profile;
