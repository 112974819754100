import { Col, Row } from 'antd';
import styled from 'styled-components';

const Container = styled.div<any>`
  position: absolute;
  display: flex;
  width: 80%;
  left: 10%;
  bottom: 20px;
  height: 96px;
  padding: 16px;
  background: ${(props) => (props.bgColor ? props.bgColor : 'rgba(255, 255, 255, 0.6)')};
  box-shadow: 0px 0px 8px rgba(145, 158, 171, 0.16), 0px 16px 24px -8px rgba(14, 126, 228, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  margin-top: 0px;
`;

export const SummaryBox = ({ icon, title, dailyValue, accValue, bgColor }: any) => {
  return (
    <Container bgColor={bgColor}>
      <img src={icon} style={{ marginRight: '12px' }} />
      <Row>
        <Col xs={24} style={{ height: '22px' }}>
          <p style={{ margin: '0px' }}>{title}</p>
        </Col>
        <Col xs={12}>
          <small>Daily</small>
          <p>{dailyValue}</p>
        </Col>
        <Col xs={12}>
          <small>Accomulate</small>
          <p>{accValue}</p>
        </Col>
      </Row>
    </Container>
  );
};
