import OverviewPage from 'pages/OverviewPage'
import DashboardPage from 'pages/DashboardPage'

import DashboardIcon from "assets/common/icon/dashboardicon.svg"
import OverviewIcon from "assets/common/icon/overviewicon.svg"

export const routes = [
    {
        path: '/overview',
        name: 'Overview',
        icon: OverviewIcon,
        component: OverviewPage,
        layout: '/admin',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: DashboardIcon,
        component: DashboardPage,
        layout: '/admin',
    },
]