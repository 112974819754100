export const Box4 = ({ active }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="7" height="7" rx="1" fill={active ? 'white' : '#0E7EE4'} />
      <rect x="9" width="7" height="7" rx="1" fill={active ? 'white' : '#0E7EE4'} />
      <rect y="9" width="7" height="7" rx="1" fill={active ? 'white' : '#0E7EE4'} />
      <rect x="9" y="9" width="7" height="7" rx="1" fill={active ? 'white' : '#0E7EE4'} />
    </svg>
  );
};
