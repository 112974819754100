import { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const Container = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 42px;
  right: 0px;
  width: 200px;
  height: fit-content;
  padding: 5px 11px 10px 11px;
  background-color: white;
  border-radius: 3px;
`;

const DropdownRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 10px 4px;
  border-bottom: 0.5px solid #eaeaea;
  :hover {
    opacity: 0.8;
  }
  :last-child {
    border-bottom: 0px;
  }
`;

const TransparentBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const AnimatedDropdownContainer = animated(DropdownContainer);

type NotificationDropdownProps = {
  open: boolean;
};

const NotificationMockData: any = [{ title: 'Notification 1' }, { title: 'Notification 2' }];

const NotificationDropdown = ({ open }: NotificationDropdownProps) => {
  const dropdownProps = useSpring({
    opacity: open ? '1' : '0',
    display: open ? 'block' : 'none',
  });

  return (
    <>
      <AnimatedDropdownContainer style={dropdownProps}>
        {NotificationMockData.length !== 0 ? (
          NotificationMockData.map((noti: any) => (
            <DropdownRow
              key={noti.title}
              onClick={() => (window.location.href = '/admin/myprofile')}
            >
              {noti.title}
            </DropdownRow>
          ))
        ) : (
          <DropdownRow>No new notifications</DropdownRow>
        )}
      </AnimatedDropdownContainer>
    </>
  );
};

const Notification = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <TransparentBg onClick={() => setOpen(!open)} />}
      <Container onClick={() => setOpen(!open)}>
        <svg
          width="38"
          height="23"
          viewBox="0 0 18 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          cursor="pointer"
        >
          <path
            d="M17.5466 17.4446L16.0251 15.9231V10.0256C16.0251 6.40462 14.0907 3.37333 10.7174 2.57128V1.76923C10.7174 0.790256 9.92713 0 8.94815 0C7.96918 0 7.17892 0.790256 7.17892 1.76923V2.57128C3.79379 3.37333 1.87123 6.39282 1.87123 10.0256V15.9231L0.349691 17.4446C-0.393386 18.1877 0.125588 19.4615 1.17533 19.4615H16.7092C17.7707 19.4615 18.2897 18.1877 17.5466 17.4446ZM13.6661 17.1026H4.2302V10.0256C4.2302 7.10051 6.01123 4.71795 8.94815 4.71795C11.8851 4.71795 13.6661 7.10051 13.6661 10.0256V17.1026ZM8.94815 23C10.2456 23 11.3071 21.9385 11.3071 20.641H6.58918C6.58918 21.2667 6.83771 21.8667 7.28011 22.3091C7.7225 22.7515 8.32251 23 8.94815 23Z"
            fill="#397CDD"
          />
          {NotificationMockData.length !== 0 && (
            <>
              <circle cx="18.6217" cy="9" r="9" fill="url(#paint0_linear)" />
              <circle cx="18.6217" cy="9" r="8.5" stroke="white" strokeOpacity="0.5" />
              <text
                x={NotificationMockData.length < 10 ? '15.3' : '12.8'}
                y="13"
                fill="white"
                fontSize="10px"
              >
                {NotificationMockData.length}
              </text>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="13.1217"
                  y1="2.5"
                  x2="22.6217"
                  y2="15.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#EF4337" />
                  <stop offset="1" stopColor="#EF4337" stopOpacity="0.73" />
                </linearGradient>
              </defs>
            </>
          )}
        </svg>
        <NotificationDropdown open={open} />
      </Container>
    </>
  );
};

export default Notification;
