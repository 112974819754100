import styled from 'styled-components';

const StyledButton = styled.button<any>`
  width: 132px;
  height: 30px;
  box-shadow: 1px 2px 6px rgba(190, 196, 198, 0.2);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.bgColor ? props.bgColor : '#0e7ee4')};
  color: ${(props) => (props.color ? props.color : 'white')};
  border: 1px solid #0e7ee4;
  cursor: pointer;
  margin-bottom: 6px;
`;

export const CommonButton = ({ text, bgColor, color }: any) => {
  return (
    <>
      <StyledButton bgColor={bgColor} color={color}>
        {text}
      </StyledButton>
    </>
  );
};
