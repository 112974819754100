import styled from 'styled-components';
import { Menu, Dropdown, Row, Col, Input } from 'antd';
import { DeviceListData } from 'MockData/DeviceData';
import { ReportFilter } from './ReportFilter';
import { Switch } from 'antd';
import './style.css';
import { SwitchViewButton } from 'components/SwitchViewButton/SwitchViewButton';

const ReportPanel = styled.div`
  /* background: linear-gradient(
    101.24deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 100%
  ); */
  /* box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15); */
  backdrop-filter: blur(20px);
  border-radius: 16px;
  /* padding: 12px 18px; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
`;

const ReportTypeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ReportInputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const DropdownText = styled.p`
  margin: 0px;
  font-size: 16px;
  color: #065ba9;
`;

const DropdownValue = styled.p`
  margin: 0px;
  font-size: 16px;
  color: #065ba9;
`;

const menu = (
  <Menu>
    <Menu.Item key="0">
      <DropdownValue>Monthly e-Bill</DropdownValue>
    </Menu.Item>
    <Menu.Item key="1">
      <DropdownValue>Monthly solar generation</DropdownValue>
    </Menu.Item>
  </Menu>
);

const FloorMenu = (
  <Menu>
    <Menu.Item key="0">
      <DropdownValue>1st Floor</DropdownValue>
    </Menu.Item>
    <Menu.Item key="1">
      <DropdownValue>2nd Floor</DropdownValue>
    </Menu.Item>
  </Menu>
);

const DeviceMenu = (
  <Menu>
    <Menu.Item key="0">
      <DropdownValue>AC</DropdownValue>
    </Menu.Item>
    <Menu.Item key="1">
      <DropdownValue>Environment sensor</DropdownValue>
    </Menu.Item>
  </Menu>
);

const { Search } = Input;

export const FilterBox = ({ isToggle, onChange, activeButton, toggleViewButton }: any) => {
  const onSearch = (data: any) => {
    console.log(data);
  };
  const handleSelectDevice = (data: any) => {
    console.log(data);
  };

  return (
    <ReportPanel>
      <Row style={{ width: '100%' }}>
        <Col xs={18}>
          <ReportTypeBox>
            <ReportInputBox>
              <Search
                size="large"
                placeholder="input search text"
                onSearch={onSearch}
                enterButton
              />
            </ReportInputBox>
          </ReportTypeBox>
        </Col>
        <Col xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ReportTypeBox>
            <ReportInputBox>
              <Switch defaultChecked={isToggle} onChange={onChange} />
              <p style={{ margin: '0px 12px', width:'100px' }}>Show Map</p>
              <SwitchViewButton activeButton={activeButton} toggleViewButton={toggleViewButton} />
            </ReportInputBox>
          </ReportTypeBox>
        </Col>
      </Row>
    </ReportPanel>
  );
};
