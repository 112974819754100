import AcIconOn from 'assets/pages/Dashboard/AcIconOn.svg';
import NetatmoOn from 'assets/pages/Dashboard/NetatmoOn.svg';
import CCTV from 'assets/pages/Dashboard/CCTV.svg';
import BlindOn from 'assets/pages/Dashboard/BlindOn.svg';
import FreshAirOn from 'assets/pages/Dashboard/FreshAirOn.svg';
import AiGateway from 'assets/pages/Dashboard/AiGateway.svg';
import IoTGateway from 'assets/pages/Dashboard/IoTGateway.svg';
import { Serie } from '@nivo/line';

export type DeviceProps = {
  device_name: string;
  device_id: string;
  agent_id: string;
  ui_location: number[]
  visible?: boolean
}

export interface DeviceIconProps {
  data: DeviceProps[]
}

export const DashboardMockData = [
  {
    title: 'Load Fl.1',
    left: 78,
    top: 68,
    items: [
      {
        title: 'AC Meter',
        device: '9c:a5:25:bb:e2:00:0',
        subdevice: 3,
        currentPower: 40,
        unit: 'kW'
      },
      {
        title: 'Light/Plug Meter',
        device: '9c:a5:25:bb:e2:00:1',
        subdevice: 3,
        currentPower: 170,
        unit: 'kW'
      },
    ]
  },
  {
    title: 'Load Fl.2',
    left: 78,
    top: 55.5,
    items: [
      {
        title: 'AC Meter',
        device: '9c:a5:25:bb:e2:00:2',
        subdevice: 3,
        currentPower: 120,
        unit: 'kW'
      },
      {
        title: 'Light/Plug Meter',
        device: '9c:a5:25:bb:e2:00:3',
        subdevice: 3,
        currentPower: 80,
        unit: 'kW'
      },
    ]
  },
  {
    title: 'Buy-in Power',
    left: 20,
    top: 77,
    items: [
      {
        title: 'MEA Meter',
        currentPower: 200,
        unit: 'kW'
      },
    ]
  },
  {
    title: 'Output Power',
    left: 24,
    top: 24,
    items: [
      {
        title: 'Inverter01',
        currentPower: 40,
        unit: 'kW'
      },
      {
        title: 'Inverter02',
        currentPower: 170,
        unit: 'kW'
      },
      {
        title: 'Inverter03',
        currentPower: 40,
        unit: 'kW'
      },
    ]
  },
  {
    title: 'People Fl.1',
    left: 91,
    top: 68,
    unit: 'unit',
    items: [
      {
        title: 'Camera1',
        currentPower: 10,
        unit: 'unit'
      },
    ]
  },
  {
    title: 'People Fl.2',
    left: 91,
    top: 55.5,
    unit: 'unit',
    items: [
      {
        title: 'Camera1',
        currentPower: 10,
        unit: 'unit'
      },
      {
        title: 'Camera2',
        currentPower: 18,
        unit: 'unit'
      },
    ]
  },
]

export const DeviceMockDataFloor1: DeviceIconProps['data'] = [
  {
    device_name: 'ac_1',
    device_id: 'ac_1',
    agent_id: 'bac0hvac',
    ui_location: [6.5, 0]
  },
  {
    device_name: 'ac_2',
    device_id: 'ac_2',
    agent_id: 'bac0hvac',
    ui_location: [13.2, 0]
  },
  {
    device_name: 'ac_3',
    device_id: 'ac_3',
    agent_id: 'bac0hvac',
    ui_location: [19.9, 0]
  },
  {
    device_name: 'ac_4',
    device_id: 'ac_4',
    agent_id: 'bac0hvac',
    ui_location: [26.6, 0]
  },
  {
    device_name: 'ac_5',
    device_id: 'ac_5',
    agent_id: 'bac0hvac',
    ui_location: [33.3, 0]
  },
  {
    device_name: 'ac_6',
    device_id: 'ac_6',
    agent_id: 'bac0hvac',
    ui_location: [40, 0]
  },
  {
    device_name: 'ac_7',
    device_id: 'ac_7',
    agent_id: 'bac0hvac',
    ui_location: [61.3, 0]
  },
  {
    device_name: 'ac_8',
    device_id: 'ac_8',
    agent_id: 'bac0hvac',
    ui_location: [68, 0]
  },
  {
    device_name: 'ac_9',
    device_id: 'ac_9',
    agent_id: 'bac0hvac',
    ui_location: [74.7, 0]
  },
  {
    device_name: 'ac_10',
    device_id: 'ac_10',
    agent_id: 'bac0hvac',
    ui_location: [81.4, 0]
  },
  {
    device_name: 'ac_11',
    device_id: 'ac_11',
    agent_id: 'bac0hvac',
    ui_location: [88.1, 0]
  },
  {
    device_name: 'ac_12',
    device_id: 'ac_12',
    agent_id: 'bac0hvac',
    ui_location: [94.8, 0]
  },
  {
    device_name: 'env1',
    device_id: 'env001',
    agent_id: 'netatmo',
    ui_location: [15.2, 22]
  },
  {
    device_name: 'env2',
    device_id: 'env002',
    agent_id: 'netatmo',
    ui_location: [26, 22]
  },
  {
    device_name: 'env3',
    device_id: 'env003',
    agent_id: 'netatmo',
    ui_location: [34, 22]
  },
  {
    device_name: 'env4',
    device_id: 'env004',
    agent_id: 'netatmo',
    ui_location: [40, 22]
  },
  {
    device_name: 'env5',
    device_id: 'env005',
    agent_id: 'netatmo',
    ui_location: [62.3, 22]
  },
  {
    device_name: 'env6',
    device_id: 'env006',
    agent_id: 'netatmo',
    ui_location: [74, 22]
  },
  {
    device_name: 'env7',
    device_id: 'env007',
    agent_id: 'netatmo',
    ui_location: [81, 22]
  },
  {
    device_name: 'env8',
    device_id: 'env008',
    agent_id: 'netatmo',
    ui_location: [88, 22]
  },
  {
    device_name: 'env9',
    device_id: 'env009',
    agent_id: 'netatmo',
    ui_location: [95, 22]
  },
  {
    device_name: 'env10',
    device_id: 'env010',
    agent_id: 'netatmo',
    ui_location: [16, 45.5]
  },
  {
    device_name: 'env11',
    device_id: 'env011',
    agent_id: 'netatmo',
    ui_location: [22, 70.5]
  },
  {
    device_name: 'env12',
    device_id: 'env012',
    agent_id: 'netatmo',
    ui_location: [39, 75]
  },
  {
    device_name: 'env13',
    device_id: 'env013',
    agent_id: 'netatmo',
    ui_location: [68, 45.5]
  },
  {
    device_name: 'env14',
    device_id: 'env014',
    agent_id: 'netatmo',
    ui_location: [79, 60]
  },
  {
    device_name: 'env15',
    device_id: 'env015',
    agent_id: 'netatmo',
    ui_location: [90, 60]
  },
  {
    device_name: 'cctv1',
    device_id: '1st-floor-front',
    agent_id: 'cctv',
    ui_location: [56, 52]
  },
  {
    device_name: 'freshair1',
    device_id: 'freshair01',
    agent_id: 'tasmota',
    ui_location: [98, 35]
  },
  {
    device_name: 'freshair2',
    device_id: 'freshair02',
    agent_id: 'tasmota',
    ui_location: [3, 25]
  },
  {
    device_name: 'aigateway1',
    device_id: 'aigateway01',
    agent_id: 'aigateway',
    ui_location: [5, 45]
  },
  {
    device_name: 'iotgateway1',
    device_id: 'iotgateway01',
    agent_id: 'iotgateway',
    ui_location: [5, 55]
  },
]

export const DeviceMockDataFloor2: DeviceIconProps['data'] = [
  {
    device_name: 'ac_13',
    device_id: 'ac_13',
    agent_id: 'bac0hvac',
    ui_location: [6.5, 0]
  },
  {
    device_name: 'ac_14',
    device_id: 'ac_14',
    agent_id: 'bac0hvac',
    ui_location: [13.2, 0]
  },
  {
    device_name: 'ac_15',
    device_id: 'ac_15',
    agent_id: 'bac0hvac',
    ui_location: [19.9, 0]
  },
  {
    device_name: 'ac_16',
    device_id: 'ac_16',
    agent_id: 'bac0hvac',
    ui_location: [26.6, 0]
  },
  {
    device_name: 'ac_17',
    device_id: 'ac_17',
    agent_id: 'bac0hvac',
    ui_location: [33.3, 0]
  },
  {
    device_name: 'ac_18',
    device_id: 'ac_18',
    agent_id: 'bac0hvac',
    ui_location: [40, 0]
  },
  {
    device_name: 'ac_19',
    device_id: 'ac_19',
    agent_id: 'bac0hvac',
    ui_location: [60.5, 0]
  },
  {
    device_name: 'ac_20',
    device_id: 'ac_20',
    agent_id: 'bac0hvac',
    ui_location: [67.5, 0]
  },
  {
    device_name: 'ac_21',
    device_id: 'ac_21',
    agent_id: 'bac0hvac',
    ui_location: [74.6, 0]
  },
  {
    device_name: 'ac_22',
    device_id: 'ac_22',
    agent_id: 'bac0hvac',
    ui_location: [81.4, 0]
  },
  {
    device_name: 'ac_23',
    device_id: 'ac_23',
    agent_id: 'bac0hvac',
    ui_location: [88.1, 0]
  },
  {
    device_name: 'ac_24',
    device_id: 'ac_24',
    agent_id: 'bac0hvac',
    ui_location: [94.8, 0]
  },
  {
    device_name: 'env1',
    device_id: '0xc449',
    agent_id: 'netatmo',
    ui_location: [14, 22]
  },
  {
    device_name: 'env2',
    device_id: '0xb2b6',
    agent_id: 'netatmo',
    ui_location: [21.5, 22]
  },
  {
    device_name: 'env3',
    device_id: 'env003',
    agent_id: 'netatmo',
    ui_location: [29, 22]
  },
  {
    device_name: 'env4',
    device_id: 'env004',
    agent_id: 'netatmo',
    ui_location: [36.5, 22]
  },
  {
    device_name: 'env4',
    device_id: 'env004',
    agent_id: 'netatmo',
    ui_location: [50, 22]
  },
  {
    device_name: 'env5',
    device_id: 'env005',
    agent_id: 'netatmo',
    ui_location: [61.6, 22]
  },
  {
    device_name: 'env6',
    device_id: 'env006',
    agent_id: 'netatmo',
    ui_location: [68.5, 22]
  },
  {
    device_name: 'env7',
    device_id: 'env007',
    agent_id: 'netatmo',
    ui_location: [75.5, 22]
  },
  {
    device_name: 'env8',
    device_id: 'env008',
    agent_id: 'netatmo',
    ui_location: [82.8, 22]
  },
  {
    device_name: 'env9',
    device_id: 'env009',
    agent_id: 'netatmo',
    ui_location: [90, 22]
  },
  {
    device_name: 'env10',
    device_id: 'env010',
    agent_id: 'netatmo',
    ui_location: [20.5, 47]
  },
  {
    device_name: 'env11',
    device_id: 'env011',
    agent_id: 'netatmo',
    ui_location: [20.5, 67.5]
  },
  {
    device_name: 'env12',
    device_id: 'env012',
    agent_id: 'netatmo',
    ui_location: [33, 47]
  },
  {
    device_name: 'env13',
    device_id: 'env013',
    agent_id: 'netatmo',
    ui_location: [70, 38]
  },
  {
    device_name: 'env14',
    device_id: 'env014',
    agent_id: 'netatmo',
    ui_location: [77, 70]
  },
  {
    device_name: 'env15',
    device_id: 'env015',
    agent_id: 'netatmo',
    ui_location: [83, 45]
  },
  {
    device_name: 'env16',
    device_id: 'env016',
    agent_id: 'netatmo',
    ui_location: [95, 65]
  },
  {
    device_name: 'cctv2',
    device_id: '2nd-floor-left',
    agent_id: 'cctv',
    ui_location: [96, 30]
  },
  {
    device_name: 'cctv3',
    device_id: '2nd-floor-right',
    agent_id: 'cctv',
    ui_location: [4, 30]
  },
  {
    device_name: 'freshair1',
    device_id: 'freshair01',
    agent_id: 'freshair',
    ui_location: [98, 10]
  },
  {
    device_name: 'freshair2',
    device_id: 'freshair02',
    agent_id: 'freshair',
    ui_location: [3, 10]
  },
  {
    device_name: 'aigateway1',
    device_id: 'aigateway01',
    agent_id: 'aigateway',
    ui_location: [5, 45]
  },
  {
    device_name: 'iotgateway1',
    device_id: 'iotgateway01',
    agent_id: 'iotgateway',
    ui_location: [5, 55]
  },
  {
    device_name: 'blind1',
    device_id: 'blind01',
    agent_id: 'blind',
    ui_location: [5, 89]
  },
  {
    device_name: 'blind2',
    device_id: 'blind02',
    agent_id: 'blind',
    ui_location: [9.4, 89]
  },
  {
    device_name: 'blind3',
    device_id: 'blind03',
    agent_id: 'blind',
    ui_location: [13.8, 89]
  },
  {
    device_name: 'blind4',
    device_id: 'blind04',
    agent_id: 'blind',
    ui_location: [18.8, 89]
  },
  {
    device_name: 'blind5',
    device_id: 'blind05',
    agent_id: 'blind',
    ui_location: [23.1, 89]
  },
  {
    device_name: 'blind6',
    device_id: 'blind06',
    agent_id: 'blind',
    ui_location: [27.4, 89]
  },

  {
    device_name: 'blind7',
    device_id: 'blind07',
    agent_id: 'blind',
    ui_location: [32.4, 89]
  },
  {
    device_name: 'blind8',
    device_id: 'blind08',
    agent_id: 'blind',
    ui_location: [36.7, 89]
  },
  {
    device_name: 'blind9',
    device_id: 'blind09',
    agent_id: 'blind',
    ui_location: [41, 89]
  },

  {
    device_name: 'blind10',
    device_id: 'blind010',
    agent_id: 'blind',
    ui_location: [46, 89]
  },
  {
    device_name: 'blind11',
    device_id: 'blind011',
    agent_id: 'blind',
    ui_location: [50.3, 89]
  },
  {
    device_name: 'blind12',
    device_id: 'blind012',
    agent_id: 'blind',
    ui_location: [54.6, 89]
  },

  {
    device_name: 'blind13',
    device_id: 'blind013',
    agent_id: 'blind',
    ui_location: [59.6, 89]
  },
  {
    device_name: 'blind8',
    device_id: 'blind08',
    agent_id: 'blind',
    ui_location: [63.9, 89]
  },
  {
    device_name: 'blind9',
    device_id: 'blind09',
    agent_id: 'blind',
    ui_location: [68.2, 89]
  },

  {
    device_name: 'blind10',
    device_id: 'blind010',
    agent_id: 'blind',
    ui_location: [73.2, 89]
  },
  {
    device_name: 'blind11',
    device_id: 'blind011',
    agent_id: 'blind',
    ui_location: [77.5, 89]
  },
  {
    device_name: 'blind12',
    device_id: 'blind012',
    agent_id: 'blind',
    ui_location: [81.8, 89]
  },

  {
    device_name: 'blind10',
    device_id: 'blind010',
    agent_id: 'blind',
    ui_location: [86.8, 89]
  },
  {
    device_name: 'blind11',
    device_id: 'blind011',
    agent_id: 'blind',
    ui_location: [91.1, 89]
  },
  {
    device_name: 'blind12',
    device_id: 'blind012',
    agent_id: 'blind',
    ui_location: [95.4, 89]
  },
]
export const FirebaseDeviceMockData: any = {
  'ac_1': {
    temp: 21,
  },
  'ac_2': {
    temp: 22,
  },
  'ac_3': {
    temp: 23,
  },
  'ac_4': {
    temp: 24,
  },
  'ac005': {
    temp: 25,
  },
  'ac006': {
    temp: 26,
  },
  'ac007': {
    temp: 27,
  },
  'ac008': {
    temp: 28,
  },
  'ac009': {
    temp: 29,
  },
  'ac010': {
    temp: 24,
  },
  'ac011': {
    temp: 29,
  },
  'ac012': {
    temp: 24,
  }
}

export const DeviceMockDataFloor1Self: DeviceIconProps['data'] = [
  {
    device_name: 'ac_1',
    device_id: 'ac001',
    agent_id: 'actooltip',
    ui_location: [6.5, 0]
  },
  {
    device_name: 'ac_2',
    device_id: 'ac002',
    agent_id: 'actooltip',
    ui_location: [13.2, 0]
  },
  {
    device_name: 'ac_3',
    device_id: 'ac003',
    agent_id: 'actooltip',
    ui_location: [19.9, 0]
  },
  {
    device_name: 'ac_4',
    device_id: 'ac004',
    agent_id: 'actooltip',
    ui_location: [26.6, 0]
  },
  {
    device_name: 'ac_5',
    device_id: 'ac005',
    agent_id: 'actooltip',
    ui_location: [33.3, 0]
  },
  {
    device_name: 'ac_6',
    device_id: 'ac006',
    agent_id: 'actooltip',
    ui_location: [40, 0]
  },
  {
    device_name: 'ac7',
    device_id: 'ac007',
    agent_id: 'actooltip',
    ui_location: [61.3, 0]
  },
  {
    device_name: 'ac8',
    device_id: 'ac008',
    agent_id: 'actooltip',
    ui_location: [68, 0]
  },
  {
    device_name: 'ac9',
    device_id: 'ac009',
    agent_id: 'actooltip',
    ui_location: [74.7, 0]
  },
  {
    device_name: 'ac10',
    device_id: 'ac010',
    agent_id: 'actooltip',
    ui_location: [81.4, 0]
  },
  {
    device_name: 'ac11',
    device_id: 'ac011',
    agent_id: 'actooltip',
    ui_location: [88.1, 0]
  },
  {
    device_name: 'ac12',
    device_id: 'ac012',
    agent_id: 'actooltip',
    ui_location: [94.8, 0]
  },
]

export type DeviceListProps = {
  agentId: string;
  icon: any;
  isOpened: boolean;
  name: string;
}[];

export const DeviceListData: DeviceListProps = [
  {
    agentId: 'bac0hvac',
    icon: AcIconOn,
    isOpened: true,
    name: 'Air-condition',
  },
  {
    agentId: 'netatmo',
    icon: NetatmoOn,
    isOpened: true,
    name: 'Environment Sensor',
  },
  {
    agentId: 'son_env',
    icon: NetatmoOn,
    isOpened: true,
    name: 'Environment Sensor (Sonoff)',
  },
  {
    agentId: 'blind',
    icon: BlindOn,
    isOpened: true,
    name: 'Blind',
  },
  {
    agentId: 'cctv',
    icon: CCTV,
    isOpened: true,
    name: 'CCTV',
  },
  {
    agentId: 'tasmota',
    icon: FreshAirOn,
    isOpened: true,
    name: 'Fresh Air',
  },
  {
    agentId: 'aigateway',
    icon: AiGateway,
    isOpened: true,
    name: 'Ai Gateway',
  },
  {
    agentId: 'iotgateway',
    icon: IoTGateway,
    isOpened: true,
    name: 'IoT Gateway',
  },
];

export const EmptyDashboardData = [
  {
    key: 'floor_1',
    title: 'Load Fl.1',
    left: 78,
    top: 68,
    items: [
      {
        title: 'Light/Plug Meter',
        device: '9c:a5:25:bb:e2:00:1',
        subdevice: 3,
        currentPower: 0,
        unit: 'kW',
      },
      {
        title: 'AC Meter',
        device: '9c:a5:25:bb:e2:00:2',
        subdevice: 3,
        currentPower: 0,
        unit: 'kW',
      },
    ],
  },
  {
    key: 'floor_2',
    title: 'Load Fl.2',
    left: 78,
    top: 55.5,
    items: [
      {
        title: 'Light/Plug Meter',
        device: '9c:a5:25:bb:e2:00:3',
        subdevice: 3,
        currentPower: 0,
        unit: 'kW',
      },
      {
        title: 'AC Meter',
        device: '9c:a5:25:bb:e2:00:4',
        subdevice: 3,
        currentPower: 0,
        unit: 'kW',
      },
    ],
  },
  {
    key: 'grid',
    title: 'Buy-in Power',
    left: 20,
    top: 77,
    items: [
      {
        title: 'MEA Meter',
        currentPower: 0,
        unit: 'kW',
      },
    ],
  },
  {
    key: 'solar',
    title: 'Output Power',
    left: 24,
    top: 24,
    items: [
      {
        title: 'Inverter01',
        currentPower: 0,
        unit: 'kW',
        timestamp: ''
      },
      {
        title: 'Inverter02',
        currentPower: 0,
        unit: 'kW',
        timestamp: ''
      },
      {
        title: 'Inverter03',
        currentPower: 0,
        unit: 'kW',
        timestamp: ''
      },
    ],
  },
  {
    key: 'people',
    title: 'People',
    left: 40,
    top: 50,
    unit: 'unit',
    type: 'camera',
    items: [
      {
        title: 'Fl.1 Front Camera',
        currentPower: 0,
        unit: 'people',
      },
      {
        title: 'Fl.2 Left Camera',
        currentPower: 0,
        unit: 'people',
      },
      {
        title: 'Fl.2 Right Camera',
        currentPower: 0,
        unit: 'people',
      },
    ],
  },
];

export const MockEnergyConsumptionData = [
  {
    id: 'make',
    label: 'make',
    value: 595,
    color: 'hsl(217, 70%, 50%)',
  },
  {
    id: 'stylus',
    label: 'stylus',
    value: 401,
    color: 'hsl(120, 70%, 50%)',
  },
];

type SummaryDataProps = {
  realtimePowerConsumption?: Serie[] | [],
  dailyEnergyConsumption?: Serie[] | [],
  monthlyEnergyConsumption?: Serie[] | [],
  ranking?: Serie[] | [],
  co2Emission?: {
    today: number,
    thisMonth: number
  }

}

export const MockRealtimePowerCon: SummaryDataProps = {
  realtimePowerConsumption: [
    {
      id: 'realtimePowerConsumption',
      color: '#8BC6FF',
      data: [
        { x: '2021-11-16 00:00', y: 3.5 },
        { x: '2021-11-16 00:30', y: 6.5 },
        { x: '2021-11-16 01:00', y: 9.2 },
        { x: '2021-11-16 01:30', y: 5.3 },
        { x: '2021-11-16 02:00', y: 7.1 },
        { x: '2021-11-16 02:30', y: 8.2 },
        { x: '2021-11-16 03:00', y: 5.7 },
        { x: '2021-11-16 03:30', y: 7.3 },
        { x: '2021-11-16 04:00', y: 6.7 },
        { x: '2021-11-16 04:30', y: 7.1 },
        { x: '2021-11-16 05:00', y: 7.9 },
        { x: '2021-11-16 05:30', y: 8.2 },
        { x: '2021-11-16 06:00', y: 8.5 },
        { x: '2021-11-16 06:30', y: 9.2 },
        { x: '2021-11-16 07:00', y: 9.1 },
        { x: '2021-11-16 07:30', y: 12.4 },
        { x: '2021-11-16 08:00', y: 37.3 },
        { x: '2021-11-16 08:30', y: 49.7 },
        { x: '2021-11-16 09:00', y: 82.6 },
        { x: '2021-11-16 09:30', y: 103.3 },
        { x: '2021-11-16 10:00', y: 118.6 },
        { x: '2021-11-16 10:30', y: 127.3 },
        { x: '2021-11-16 11:00', y: 122.6 },
        { x: '2021-11-16 11:30', y: 118.1 },
        { x: '2021-11-16 12:00', y: 142.2 },
        { x: '2021-11-16 12:30', y: 153.1 },
        { x: '2021-11-16 13:00', y: 142.7 },
        { x: '2021-11-16 13:30', y: 158.5 },
        { x: '2021-11-16 14:00', y: 162.2 },
        { x: '2021-11-16 14:30', y: 147.6 },
        { x: '2021-11-16 15:00', y: 164.5 },
        { x: '2021-11-16 15:30', y: 152.6 },
        { x: '2021-11-16 16:00', y: 136.2 },
        { x: '2021-11-16 16:30', y: 112.1 },
        { x: '2021-11-16 17:00', y: 81.5 },
        { x: '2021-11-16 17:30', y: 72.5 },
        { x: '2021-11-16 18:00', y: 42.6 },
        { x: '2021-11-16 18:30', y: 41.9 },
        { x: '2021-11-16 19:00', y: 32.2 },
        // { x: '2021-11-16 19:30', y: 26.3 },
        // { x: '2021-11-16 20:00', y: 25.3 },
        // { x: '2021-11-16 20:30', y: 23.6 },
        // { x: '2021-11-16 21:00', y: 17.2 },
        // { x: '2021-11-16 21:30', y: 20.1 },
        // { x: '2021-11-16 22:00', y: 21.1 },
        // { x: '2021-11-16 22:30', y: 27.5 },
        // { x: '2021-11-16 23:00', y: 22.4 },
        // { x: '2021-11-16 23:30', y: 28.3 },
      ]
    },
  ],
  dailyEnergyConsumption: [
    {
      id: 'dailyEnergyConsumption',
      color: '#8BC6FF',
      data: [
        { x: '2021-11-01 00:00', y: 421.5 },
        { x: '2021-11-02 00:00', y: 482.1 },
        { x: '2021-11-03 00:00', y: 491.4 },
        { x: '2021-11-04 00:00', y: 409.1 },
        { x: '2021-11-05 00:00', y: 443.0 },
        { x: '2021-11-06 00:00', y: 14.5 },
        { x: '2021-11-07 00:00', y: 11.2 },
        { x: '2021-11-08 00:00', y: 489.3 },
        { x: '2021-11-09 00:00', y: 427.2 },
        { x: '2021-11-10 00:00', y: 415.9 },
        { x: '2021-11-11 00:00', y: 385.7 },
      ]
    },
  ],
  ranking: [
    {
      id: 'dailyEnergyConsumption',
      color: '#8BC6FF',
      data: [
        { x: '2021-11-01 00:00', y: 421.5 },
        { x: '2021-11-02 00:00', y: 482.1 },
        { x: '2021-11-03 00:00', y: 491.4 },
        { x: '2021-11-04 00:00', y: 409.1 },
        { x: '2021-11-05 00:00', y: 443.0 },
        { x: '2021-11-06 00:00', y: 314.5 },
        { x: '2021-11-07 00:00', y: 511.2 },
        { x: '2021-11-08 00:00', y: 489.3 },
        { x: '2021-11-09 00:00', y: 427.2 },
        { x: '2021-11-10 00:00', y: 415.9 },
        { x: '2021-11-11 00:00', y: 385.7 },
      ]
    },
  ],
  monthlyEnergyConsumption: [
    {
      id: 'monthlyEnergyConsumption',
      color: '#8BC6FF',
      data: [
        { x: '2021-01-30 00:00', y: 12043 },
        { x: '2021-02-28 00:00', y: 13294 },
        { x: '2021-03-30 00:00', y: 12358 },
        { x: '2021-04-30 00:00', y: 11582 },
        { x: '2021-05-30 00:00', y: 12395 },
        { x: '2021-06-30 00:00', y: 12558 },
        { x: '2021-07-30 00:00', y: 11895 },
        { x: '2021-08-30 00:00', y: 13290 },
        { x: '2021-09-30 00:00', y: 12904 },
        { x: '2021-10-30 00:00', y: 12490 },
        { x: '2021-11-30 00:00', y: 18942 },
      ]
    },
  ],
  co2Emission: {
    today: 426,
    thisMonth: 2317
  }
}