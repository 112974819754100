import styled from 'styled-components';
import { Box4 } from 'assets/common/icon/Box4';
import { Box2 } from 'assets/common/icon/Box2';

const ButtonContainer = styled.div`
  width: 90px;
  height: 30px;
  display: flex;
  border: 1px solid #0e7ee4;
  border-radius: 6px;
  overflow: hidden;
`;

const Button = styled.div<any>`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? '#0E7EE4' : 'transparent')};
  cursor: pointer;
`;

export const SwitchViewButton = ({ activeButton, toggleViewButton }: any) => {
  return (
    <>
      <ButtonContainer>
        <Button
          active={activeButton === 'gridView'}
          onClick={() => toggleViewButton('gridView')}
        >
          <Box4 active={activeButton === 'gridView'} />
        </Button>
        <Button active={activeButton === 'thumbnailView'} onClick={() => toggleViewButton('thumbnailView')}>
          <Box2 active={activeButton === 'thumbnailView'} />
        </Button>
      </ButtonContainer>
    </>
  );
};
