import { useState } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import IotDisconnected from 'assets/common/icon/IotDisconnected.svg';
import RequestService from 'assets/common/icon/RequestService.svg';
import UnderRepaired from 'assets/common/icon/UnderRepaired.svg';
import Repaired from 'assets/common/icon/Repaired.svg';
import Location from 'assets/pages/Dashboard/Location.svg';
import Power from 'assets/pages/Dashboard/Power.svg';
import WaterPump from 'assets/pages/Dashboard/WaterPump.svg';
import clock from 'assets/common/icon/clock.svg';
import { convertDatetimeToStr } from 'utils/convertDatetime';
import { FiPlus } from 'react-icons/fi';
import { ReportFilter } from './ReportFilter';
import { DeviceListData } from 'MockData/DeviceData';
import { LoadingPage } from 'components/Common/LoadingPage/LoadingPage';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24);
  border: 1px solid rgba(79, 79, 79, 0.1);
  cursor: pointer;
  height: 350px;
  @media only screen and (min-width: 1400px) {
    height: 300px;
  }
  :hover {
    box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.2);
  }
`;

const StationImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
`;

const StationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StationBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 8px 18px 28px 18px;
  background: white;
  border-radius: 0px 0px 16px 16px;
`;

const StationName = styled.p`
  font-size: 18px;
  margin-bottom: 6px;
  font-weight: 600;
`;

const StationDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const IconImage = styled.img`
  height: 16px;
  width: 14px;
  object-fit: contain;
  margin-right: 4px;
  margin-top: 2px;
`;

const Value = styled.p`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 12px;
  height: 34px;
  margin: 0px 0px 0px 16px;
  :first-line {
    line-height: 0.8em;
  }
`;

const Unit = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 6px 0px 0px 16px;
  :first-line {
    line-height: 0.8em;
  }
`;

const UpdatedTimeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  background: linear-gradient(
    154.82deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.2);
  backdrop-filter: blur(20px);
  width: fit-content;
  position: absolute;
  bottom: -12px;
  padding: 0px 12px;
  border-radius: 12px;
  color: rgba(6, 91, 169, 1);
  font-size: 12px;
`;

const AddCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(189, 190, 200, 0.1);
  border: 1px dashed #bdbec8;
  box-sizing: border-box;
  box-shadow: 1.4px 4.2px 18.2px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(28px);
  border-radius: 16px;
`;

const StatusBox = styled.div<any>`
  display: flex;
  align-items: center;
  height: 28px;
  width: auto;
  padding: 4px 12px;
  border-radius: 16px;
  margin-right: 12px;
  background: ${({ bgColor }) => bgColor};
  white-space: pre;
  margin-bottom: 6px;
  margin-top: 6px;
`;

const Shade = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 91, 169, 0.05);
`;

const SeeDetailsButton = styled.button`
  height: 40px;
  width: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(253, 253, 253, 0.3);
  border: 1px solid #ffffff;
  box-shadow: 1px 2px 6px rgba(190, 196, 198, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  :hover {
    background: rgba(167, 167, 167, 0.3);
  }
`;

const GridViewContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  overflow: scroll;
`;

const District = styled.h4`
  font-weight: 600;
  font-size: 18px;
  color: #212529;
`;

const Square = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(props) => (props.bgColor ? props.bgColor : '#d8e5fe')};
  border: ${(props) => (props.border ? props.border : '#d8e5fe')};
  margin-bottom: 6px;
  cursor: pointer;
  :after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SiteName = styled.p`
  text-align: center;
  cursor: pointer;
`;

const SignalList = [
  {
    title: 'IOT Disconnected',
    icon: IotDisconnected,
    bgColor:
      'linear-gradient(276.05deg, rgba(247, 161, 155, 0.5) 3.01%, rgba(247, 161, 155, 0.2) 100%)',
    fontColor: '#EF4337',
  },
  {
    title: 'Request Service',
    icon: RequestService,
    bgColor:
      'linear-gradient(276.05deg, rgba(250, 210, 145, 0.5) 3.01%, rgba(250, 210, 145, 0.2) 100%)',
    fontColor: '#EA663F',
  },
  {
    title: 'Under Repaired',
    icon: UnderRepaired,
    bgColor:
      'linear-gradient(276.05deg, rgba(139, 198, 255, 0.5) 3.01%, rgba(139, 198, 255, 0.2) 100%)',
    fontColor: '#065BA9',
  },
  {
    title: 'Repaired',
    icon: Repaired,
    bgColor:
      'linear-gradient(276.05deg, rgba(89, 209, 206, 0.4) 3.01%, rgba(89, 209, 206, 0.2) 100%)',
    fontColor: '#14B8B4',
  },
];

const BgColorMap: any = {
  '': { bgColor: '#d8e5fe', border: '', icon: '' },
  'IoT Disconnected': {
    bgColor: 'rgba(239, 67, 55, 0.2)',
    border: '1px solid #EF4337',
    icon: IotDisconnected,
  },
  'Request Service': {
    bgColor: 'rgba(245, 166, 35, 0.2)',
    border: '1px solid #EA663F',
    icon: RequestService,
  },
  'Under Repaired': {
    bgColor: 'rgba(6, 91, 169, 0.2)',
    border: '1px solid #065BA9',
    icon: UnderRepaired,
  },
  Repaired: { bgColor: 'rgba(20, 184, 180, 0.2)', border: '1px solid #14B8B4', icon: Repaired },
};

const checkData = (data: any) => {
  if (!data) {
    return '-';
  } else {
    return data;
  }
};

const getEnergy = (data: any) => {
  if (data) {
    const length = data.length;
    const lastEnergy = data[length - 1]['energy'];
    const firstEnergy = data[0]['energy'];
    const energyGeneration = (lastEnergy - firstEnergy).toFixed(1);
    const water = ((lastEnergy - firstEnergy) * 7.85).toFixed(1);
    return {
      energy: energyGeneration,
      water: water,
    };
  } else {
    return {
      energy: '-',
      water: '-',
    };
  }
};

const UpdatedTime = ({ date }: { date: Date | string }) => {
  if (!date) {
    return null;
  }
  let datetime: Date;
  if (typeof date === 'string') {
    datetime = new Date(date);
  } else {
    datetime = date;
  }

  return (
    <>
      <UpdatedTimeBox>
        <img src={clock} style={{ marginRight: '8px', marginTop: '0px' }} />
        {convertDatetimeToStr(datetime)}
      </UpdatedTimeBox>
    </>
  );
};

export const SiteDescription = ({ siteItem }: any) => {
  return (
    <Row>
      <Col xs={24}>
        <StationName>{siteItem.location_name}</StationName>
      </Col>
      <Col xs={24}>
        <StationDetail>
          <IconImage src={Location} />
          <p style={{ fontSize: '12px', margin: 0, color: '#0E7EE4', lineHeight: '14px' }}>
            {siteItem.description}
          </p>
        </StationDetail>
      </Col>
      <Col xs={12}>
        <StationDetail style={{ marginBottom: 0 }}>
          <IconImage src={Power} />
          <p style={{ fontSize: '12px', margin: 0, lineHeight: '14px', marginBottom: '4px' }}>
            Energy Generation
          </p>
        </StationDetail>
        <Value>{getEnergy(siteItem?.energy_meter)['energy']}</Value>
        <Unit>kWh</Unit>
      </Col>
      <Col xs={12}>
        <StationDetail style={{ marginBottom: 0 }}>
          <IconImage src={WaterPump} />
          <p style={{ fontSize: '12px', margin: 0, lineHeight: '14px', marginBottom: '4px' }}>
            Water Pumped
          </p>
        </StationDetail>
        <Value>{getEnergy(siteItem?.energy_meter)['water']}</Value>
        <Unit>Cubic m.</Unit>
      </Col>
    </Row>
  );
};

const SiteCard = ({ siteItem, onSelect }: any) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Col xs={24} sm={24} lg={8} xl={6}>
        <Card onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <StationImageContainer>
            <StationImage src={siteItem.image} onClick={() => onSelect(siteItem)} />
            {isHovered && (
              <Shade>
                <SeeDetailsButton onClick={() => onSelect(siteItem)}>See details</SeeDetailsButton>
              </Shade>
            )}
          </StationImageContainer>
          <StationBody>
            <SiteDescription siteItem={siteItem} />
          </StationBody>
          <UpdatedTime date={siteItem.registered_on} />
        </Card>
      </Col>
    </>
  );
};

const SiteGrid = ({ onSelect, siteData }: any) => {
  return (
    <>
      <Row>
        <Col xs={24}>
          <Square
            bgColor={
              BgColorMap &&
              BgColorMap[siteData?.problem_type] &&
              BgColorMap[siteData?.problem_type]['bgColor']
            }
            border={
              BgColorMap &&
              BgColorMap[siteData?.problem_type] &&
              BgColorMap[siteData?.problem_type]['border']
            }
            onClick={() => onSelect(siteData)}
          >
            <Content>
              <img
                style={{ width: '30px' }}
                src={
                  BgColorMap &&
                  BgColorMap[siteData?.problem_type] &&
                  BgColorMap[siteData?.problem_type]['icon']
                }
              />
            </Content>
          </Square>
        </Col>
        <Col xs={24}>
          <SiteName onClick={() => onSelect(siteData)}>{siteData?.location_name}</SiteName>
        </Col>
      </Row>
    </>
  );
};

export const AllStations = ({ data, onSelect, handleSelectDevice, activeButton }: any) => {
  return (
    <>
      <Row style={{ borderBottom: '1px solid #E1E6F0', paddingBottom: '12px', paddingTop: '12px' }}>
        <Col xs={5} sm={3} md={3} lg={2} xl={2}>
          <ReportFilter
            selectedDeviceList={DeviceListData}
            handleSelectDevice={handleSelectDevice}
          />
        </Col>
        <Col
          xs={19}
          sm={19}
          md={21}
          lg={20}
          xl={22}
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {SignalList.map((item) => (
            <StatusBox bgColor={item.bgColor}>
              <img src={item.icon} style={{ height: '16px', margin: '0px 12px 0px 0px' }} />
              <p style={{ margin: '0px', color: item.fontColor }}>{item.title}</p>
            </StatusBox>
          ))}
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
          height: 'calc(100vh - 176px)',
          overflow: 'scroll',
        }}
        gutter={[16, 36]}
        id="display-scroll"
      >
        {activeButton === 'thumbnailView' && (
          <>
            {data === undefined && <LoadingPage height={'100%'} />}
            {data && (
              <>
                {data.map((siteItem: any) => (
                  <>
                    <SiteCard siteItem={siteItem} onSelect={onSelect} />
                  </>
                ))}
                <Col xs={24} sm={24} lg={8} xl={6}>
                  <Card>
                    <AddCard>
                      <p style={{ textAlign: 'center', fontSize: '16px', color: '#7888796' }}>
                        <FiPlus size={30} />
                        <br />
                        ADD
                      </p>
                    </AddCard>
                  </Card>
                </Col>
              </>
            )}
          </>
        )}

        {activeButton === 'gridView' && (
          <Col xs={24}>
            <GridViewContainer>
              <District>District</District>
              <Row gutter={[20, 24]}>
                {data.map((siteItem: any) => (
                  <Col xl={2} lg={3} md={4} sm={6} xs={8}>
                    <SiteGrid siteData={siteItem} onSelect={onSelect} />
                  </Col>
                ))}
              </Row>
            </GridViewContainer>
          </Col>
        )}
      </Row>
    </>
  );
};
