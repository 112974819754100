import { useState } from 'react';
import axios from 'axios';
import { API_CONSTANTS } from './api.constants';

export default function authHeader() {
  //@ts-ignore
  const accessToken = localStorage.getItem("accessToken")
  if (accessToken) {
    return {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}

function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken?.token;
    }
    return null
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}

async function loginUser(username: string, password: string) {
  const requestOptions: any = {
    url: API_CONSTANTS.LOGIN_URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { username: username, password: password },
  };
  try {
    let responseJson = await axios.request(requestOptions);
    //@ts-ignore
    sessionStorage.setItem('login', true);
    return responseJson.data;
  } catch (error: any) {
    //@ts-ignore
    sessionStorage.setItem('login', false);
    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

async function controlAPI(payload: any) {
  const token = sessionStorage.getItem('token');
  const requestOptions = {
    url: API_CONSTANTS.DEVICES_CONTROL_URL,
    method: 'POST',

    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: payload,
  };
  try {
    //@ts-ignore
    let responseJson = await axios.request(requestOptions);
    return responseJson.data;
  } catch (error: any) {
    //## [DEBUG]
    console.error('[Services] Control Device Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

const getDeviceByFloor = (floorId: number) => {
  return axios.get(API_CONSTANTS.FLOOR, {
    headers: authHeader(),
    params: { floor_id: floorId },
  })
}

const getDeviceAllFloors = () => {
  return axios.get(API_CONSTANTS.FLOOR, {
    headers: authHeader(),
  })
}

const getDeviceHistory = ({ starttime, endtime, device_activity, device_id, subdevice_idx, sample_min }: any) => {
  return axios.get(API_CONSTANTS.HISTORY_DEVICE, {
    headers: authHeader(),
    params: { starttime, endtime, device_activity, device_id, subdevice_idx, sample_min },
  })
}

const getEnergyConsumption = (type: string, building_name: string, starttime: string, endtime: string) => {
  return axios.get(API_CONSTANTS.ENERGY_CONSUMPTION, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime },
  })
}

const getSelfDrivingAction = (starttime: string, endtime: string, building: string) => {
  return axios.get(API_CONSTANTS.SELF_DRIVING, {
    headers: authHeader(),
    params: { starttime, endtime, building },
  })
}

const postC2D = ({ device_id, output_enable }: any) => {
  const payload = {
    RequestId: 12345,
    topic: 'devicecontrol',
    action: [
      {
        device_id,
        agent_id: 'rl_action',
        subdevice_idx: 0,
        subdevice_name: 'subdev_0',
        room_name: 'garden_wing',
        command: {
          output_enable
        },
        schema: 'rein_output',
      }
    ],
    hotel_code: "BGRIMM",
    hotel: 'Bgrimm Main'
  }

  return axios.post(API_CONSTANTS.C2D, payload, {
    headers: authHeader()
  })
}

const getLocations = () => {
  return axios.get(API_CONSTANTS.LOCATION_URL, {
    headers: authHeader(),
  })
}

export { useToken, loginUser, controlAPI, getDeviceByFloor, getDeviceAllFloors, getDeviceHistory, getEnergyConsumption, getSelfDrivingAction, postC2D, getLocations };
