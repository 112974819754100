var endpoint = 'http://localhost:8080';

if (process.env.REACT_APP_BASE_URL) {
  endpoint = process.env.REACT_APP_BASE_URL;
}

const API_CONSTANTS = {
  LOGIN_URL: endpoint + '/login',
  LOCATION_URL: endpoint + '/locations',
  DEVICES_CONTROL_URL: endpoint + '/iot/devicecontrol',
  FLOOR: endpoint + '/building/floors',
  HISTORY_DEVICE: endpoint + '/building/historyroom',
  ENERGY_CONSUMPTION: 'https://energyservicebackend.azurewebsites.net' + '/api/v2.0/energy_consumption',
  SELF_DRIVING: 'https://altoiotservicebackend.azurewebsites.net' + '/api/v2.0/selfdriving',
  C2D: 'https://altobuildingiotbackend.azurewebsites.net/api/v2.0/c2d'
};

export { API_CONSTANTS };
