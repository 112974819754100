import styled from 'styled-components';
import { BlockContainer, BlockHeader, GraphContainer } from 'components/Common/components';
import { Row, Col } from 'antd';
import { Serie } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { LoadingPage } from 'components/Common/LoadingPage/LoadingPage';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 18px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 200px;
`;

export const BarChartComponent = (prop: any) => (
  <ResponsiveBar
    data={prop.data}
    // keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
    keys={['Energy Generation', 'Water Pumped']}
    indexBy="label"
    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    groupMode="grouped"
    // indexScale={{ type: 'band', round: true }}
    colors={[prop.color ? prop.color : '#FFCFDE', '#E0F0FF']}
    borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: prop.xUnit,
      legendPosition: 'middle',
      legendOffset: 32,
      // format: ticks => ticks.toString().split('_')[1]
    }}
    axisLeft={{
      tickValues: 3,
      tickSize: 2,
      tickPadding: 5,
      tickRotation: 0,
      legend: prop.yUnit,
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    enableGridY={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    labelTextColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: 0,
        translateY: -50,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolShape: 'circle',
        symbolSize: 12,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    tooltip={(props) => {
      return (
        <div
          style={{
            padding: 0,
            color: '#FE6F9A',
          }}
        >
          <strong>{props.indexValue}</strong>
          <br />
          value: {Number(props.value).toFixed(2)}
        </div>
      );
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);

export const EnergyGeneration = ({ data }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BlockHeader sm>Energy Generation & Water Pumped</BlockHeader>
          </Col>
        </Row>
        <Row>
          <GraphContainer>
            {data && (
              <BarChartComponent data={data !== undefined ? data : []} yUnit="kWh" xUnit="Days" />
            )}
            {data === undefined && <LoadingPage height={'100%'} />}
            {data === null && (
              <p
                style={{ width: '100%', textAlign: 'center', marginTop: '50px', color: '#0E7EE4' }}
              >
                Coming soon
              </p>
            )}
          </GraphContainer>
        </Row>
      </Container>
    </>
  );
};
