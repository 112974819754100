import { useState } from 'react';
import styled from 'styled-components';
import Notification from 'components/Notification';
import Profile from 'components/Profile';
import { BiTimeFive } from 'react-icons/bi';

const Container = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 20px;
  align-items: center;
  color: #788796;
  font-weight: 600;
  z-index: 99999;
`;

const Block = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageName = styled.p`
  font-size: 24px;
  margin: 0px;
  background: -webkit-linear-gradient(77.25deg, #0E7EE4 9.22%, #14B8B4 90.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

type NavbarProps = {
  pageName: string;
};

const Clock = () => {
  let time = new Date().toLocaleTimeString();
  let [ctime, setCTime] = useState<any>();
  const updateTime = () => {
    time = new Date().toLocaleTimeString();
    setCTime(time);
  };
  setInterval(updateTime, 1000);
  return (
    <>
      <p style={{ margin: '0px 30px 2px 5px' }}> {ctime}</p>
    </>
  );
};

const Navbar = ({ pageName }: NavbarProps) => {
  const hotel_code = localStorage.getItem('hotel_code');
  return (
    <>
      <Container>
        <Block>
          <PageName>{pageName.charAt(0).toUpperCase() + pageName.slice(1)}</PageName>
        </Block>
        <Block>
          <BiTimeFive />
          <Clock />
          <Notification />
          <Profile />
        </Block>
      </Container>
    </>
  );
};

export default Navbar;
