import styled from 'styled-components';
import { BlockContainer, BlockHeader, GraphContainer } from 'components/Common/components';
import { Row, Col } from 'antd';
import { LoadingPage } from 'components/Common/LoadingPage/LoadingPage';
import IotDisconnected from 'assets/common/icon/IotDisconnected.svg';
import RequestService from 'assets/common/icon/RequestService.svg';
import UnderRepaired from 'assets/common/icon/UnderRepaired.svg';
import Repaired from 'assets/common/icon/Repaired.svg';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 0px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 200px;
  overflow: hidden;
`;

const Title = styled.p`
  font-size: 12px;
  color: #788796;
  margin-bottom: 0px;
`;

const Value = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #212529;
  margin-bottom: 0px;
`;

const StyledRow = styled(Row)`
  padding: 8px 0px;
  border-bottom: 1px solid #e1e6f0;
  padding: 8px 16px;
  :hover {
    border-left: 2px solid #0E7EE4;
    background: rgba(14, 126, 228, 0.1);
  }
`;

const SignalList: { [id: string]: any } = {
  iot_disconnected: {
    title: 'IOT Disconnected',
    icon: IotDisconnected,
    bgColor:
      'linear-gradient(276.05deg, rgba(247, 161, 155, 0.5) 3.01%, rgba(247, 161, 155, 0.2) 100%)',
    fontColor: '#EF4337',
  },
  request_service: {
    title: 'Request Service',
    icon: RequestService,
    bgColor:
      'linear-gradient(276.05deg, rgba(250, 210, 145, 0.5) 3.01%, rgba(250, 210, 145, 0.2) 100%)',
    fontColor: '#EA663F',
  },
  under_repaired: {
    title: 'Under Repaired',
    icon: UnderRepaired,
    bgColor:
      'linear-gradient(276.05deg, rgba(139, 198, 255, 0.5) 3.01%, rgba(139, 198, 255, 0.2) 100%)',
    fontColor: '#065BA9',
  },
  repaired: {
    title: 'Repaired',
    icon: Repaired,
    bgColor:
      'linear-gradient(276.05deg, rgba(89, 209, 206, 0.4) 3.01%, rgba(89, 209, 206, 0.2) 100%)',
    fontColor: '#14B8B4',
  },
};

export const Activity = ({ data }: any) => {
  return (
    <>
      <Container>
        <Row
          style={{
            background: 'white',
            zIndex: 9999,
          }}
        >
          <Col xs={24} lg={24}>
            <BlockHeader sm style={{padding: '0px 18px 0px 18px'}}>Activity</BlockHeader>
          </Col>
        </Row>
        <Row style={{ height: '100%', overflow: 'scroll' }} id="display-scroll">
          <Col xs={24}>
            {data ? (
              <>
                <Row
                  style={{
                    height: '32px',
                    position: 'sticky',
                    top: '0',
                    background: 'white',
                    zIndex: 9999,
                    borderBottom: '1px solid #E1E6F0',
                    padding: '8px 16px',
                  }}
                >
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <Title>Station Name</Title>
                  </Col>
                  <Col xs={6}>
                    <Title>Added Time</Title>
                  </Col>
                  <Col xs={9}>
                    <Title>Request Title</Title>
                  </Col>
                </Row>
                {data &&
                  data.map((item: any) => (
                    <StyledRow>
                      <Col xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={SignalList[item?.id].icon} />
                      </Col>
                      <Col xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Title>Station Name</Title>
                      </Col>
                      <Col xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Value>Added Time</Value>
                      </Col>
                      <Col xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <Value>Request Value</Value>
                      </Col>
                    </StyledRow>
                  ))}
              </>
            ) : (
              <LoadingPage height={'100%'} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
