import { Col, Row } from 'antd';
import styled from 'styled-components';
import { AnalyticContent } from './AnalyticContent';
import { RealtimePowerGeneration } from './RealtimePowerGeneration';

const TdLeft = styled.td`
  font-weight: 600;
  color: #0e7ee4;
  text-align: left;
  height: 40px;
`;

const Tr = styled.tr`
  border-bottom: 1px solid #dbe4ff;
  padding: 0px 16px;
`;

export const AnalyticSection = ({ deviceInfo }: any) => {
  return (
    <Row>
      <Col xs={24}>
        <AnalyticContent />
      </Col>
    </Row>
  );
};
