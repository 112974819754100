import { Col, Row } from 'antd';
import { AlertSummary } from './AlertSummary';
import { Calendar } from './Calendar';
import { EnergyGeneration } from './EnergyGeneration';
import { IoTDevices } from './IoTDevices';
import { OutdoorWeather } from './OutdoorWeather';
import { RealtimePowerGeneration } from './RealtimePowerGeneration';

export const Summary = ({ iotDevices, powerData, energyData, alertSummaryData }: any) => {
  return (
    <>
      <Row style={{ paddingTop: '16px', paddingBottom: '16px' }} gutter={[12, 12]}>
        <Col xs={24}>
          <Calendar />
        </Col>
        <Col xs={24}>
          <EnergyGeneration data={energyData}/>
        </Col>
        <Col xs={24}>
          <RealtimePowerGeneration data={powerData} />
        </Col>
        <Col xs={24}>
          <AlertSummary data={alertSummaryData}/>
        </Col>
      </Row>
    </>
  );
};
