import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Be Vietnam", sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: auto;
  }

  button {
    font-family: "Be Vietnam", sans-serif;
  }

  h1 {
    font-size: 32px;
    font-weight: 400;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  h3 { 
    font-size: 20px;
    font-weight: 400;
  }
  h6{
    font-size: 16px;
    font-weight: 400;
  }
  caption {
    font-size: 12px;
    font-weight: 500;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -ms-overflow-style:none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
   
  ::-webkit-scrollbar{
    /* display:none; */
  }

  #display-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

  }
  #display-scroll::-webkit-scrollbar-track {
      cursor: pointer;
      /*background: red;*/
  }
  #display-scroll::-webkit-scrollbar-thumb {
      cursor: pointer;
      background-color: #CFCFCF;
      /*outline: 1px solid slategrey;*/
  }

`;
