import { useState } from 'react';
import { Col, Row } from 'antd';
import OverviewMockSummary from 'assets/pages/Overview/OverviewMockSummary.svg';
import BottomSummaryMock from 'assets/pages/Overview/BottomSummaryMock.svg';
import { KKMap } from './KKMap';
import { AlertSummary } from 'pages/DashboardPage/AlertSummary';
import { InnerContainer, PageContainer } from 'components/Common/components';
import { PumpCapacity } from './PumpCapacity';
import { Activity } from './Activity';
import { SummaryBox } from 'components/Common/SummaryBox/SummaryBox';
import EnergyGenerationIcon from 'assets/pages/Overview/EnergyGenerationIcon.svg';
import WaterPumpedIcon from 'assets/pages/Overview/WaterPumpedIcon.svg';
import Co2EmissionIcon from 'assets/pages/Overview/Co2EmissionIcon.svg';

const OverviewPage = () => {
  const [alertSummaryData, setAlertSummaryData] = useState<any>([
    {
      id: 'iot_disconnected',
      label: 'IoT Disconnected',
      value: 1,
      color: 'hsl(124, 70%, 50%)',
    },
    {
      id: 'request_service',
      label: 'Request Service',
      value: 3,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'under_repaired',
      label: 'Under Repaired',
      value: 1,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'repaired',
      label: 'Repaired',
      value: 4,
      color: 'hsl(311, 70%, 50%)',
    },
    {
      id: 'normal',
      label: 'Normal',
      value: 3,
      color: 'hsl(311, 70%, 50%)',
    },
  ]);

  const [activityData, setActivityData] = useState([
    {
      id: 'request_service',
      stationName: 'Solar Station',
      addedTime: '20:55',
      requestTitle: 'Internet disconnected',
    },
    {
      id: 'iot_disconnected',
      stationName: 'Solar Station',
      addedTime: '20:55',
      requestTitle: 'Internet disconnected',
    },
    {
      id: 'under_repaired',
      stationName: 'Solar Station',
      addedTime: '20:55',
      requestTitle: 'Internet disconnected',
    },
    {
      id: 'repaired',
      stationName: 'Solar Station',
      addedTime: '20:55',
      requestTitle: 'Internet disconnected',
    },
  ]);

  const [summaryData, setSummaryData] = useState([
    {
      icon: EnergyGenerationIcon,
      title: 'Energy Generation',
      dailyValue: 21,
      accValue: 250,
    },
    {
      icon: WaterPumpedIcon,
      title: 'Water Pumped',
      dailyValue: 21,
      accValue: 250,
    },
    {
      icon: Co2EmissionIcon,
      title: 'Co2 Emission',
      dailyValue: 21,
      accValue: 250,
    },
  ]);

  return (
    <PageContainer>
      <InnerContainer style={{ paddingRight: '0px', overflowX: 'hidden' }}>
        <Row>
          <Col xs={8}>
            <Row gutter={[12, 12]}>
              <Col xs={24}>
                <PumpCapacity />
              </Col>
              <Col xs={24}>
                <AlertSummary data={alertSummaryData} />
              </Col>
              <Col xs={24}>
                <Activity data={activityData} />
              </Col>
            </Row>
          </Col>
          <Col xs={16} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <KKMap />
            <Row style={{ width: '100%', height: 'fit-content' }}>
              {summaryData.map((item: any) => (
                <Col xs={8} style={{ height: '130px' }}>
                  <SummaryBox
                    icon={item.icon}
                    title={item.title}
                    dailyValue={item.dailyValue}
                    accValue={item.accValue}
                    bgColor={item.bgColor}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </InnerContainer>
    </PageContainer>
  );
};

export default OverviewPage;
